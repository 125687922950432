import { AnimatePresence } from "motion/react";
import Suggestions from "../components/Suggestions";
import { IMessageTypeProps } from "../typings";
import { useRichCardCustomStyles } from "../util/cssStyleUtils";
import GeneralPurposeCard from "./GeneralPurposeCard";
import GeneralPurposeCardCarousel from "./GeneralPurposeCardCarousel";

export default function RichcardMessage({
  message,
  uploadProgress,
  removeTopMargin,
  isLastMessage,
  roundBorderTop,
  roundBorderBottom,
  direction,
  mediaExtraInfo,
  isReply,
  showReplyMessage,
}: IMessageTypeProps) {
  const { richcardMessage, suggestedChipList } = message;

  const { stylesLoaded, isLoading: stylesPending } =
    useRichCardCustomStyles(message);

  if (!richcardMessage) return null;

  let card: ReturnType<
    typeof GeneralPurposeCardCarousel | typeof GeneralPurposeCard
  >;

  if (richcardMessage.message.generalPurposeCardCarousel) {
    card = (
      <GeneralPurposeCardCarousel
        message={richcardMessage.message.generalPurposeCardCarousel}
        stylesPending={stylesPending}
        stylesLoaded={stylesLoaded}
        isLastMessage={isLastMessage}
        roundBorderTop={roundBorderTop}
        roundBorderBottom={roundBorderBottom}
        direction={direction}
        mediaExtraInfo={mediaExtraInfo}
        messageId={message.msgId}
      />
    );
  } else if (richcardMessage.message.generalPurposeCard) {
    card = (
      <GeneralPurposeCard
        removeTopMargin={removeTopMargin}
        message={richcardMessage.message.generalPurposeCard}
        stylesPending={stylesPending}
        stylesLoaded={stylesLoaded}
        uploadProgress={uploadProgress}
        messageStatus={message.status}
        isLastMessage={isLastMessage}
        roundBorderTop={roundBorderTop}
        roundBorderBottom={roundBorderBottom}
        direction={direction}
        mediaExtraInfo={mediaExtraInfo}
        refId={message.referenceId}
        isReply={isReply}
        showReplyMessage={showReplyMessage}
        messageId={message.msgId}
      />
    );
  } else {
    return null;
  }

  const hideSuggestions = !isLastMessage;

  return (
    <>
      {card}
      <AnimatePresence>
        {suggestedChipList && !hideSuggestions && (
          <Suggestions
            suggestions={suggestedChipList.suggestions}
            outsideOfCard
          />
        )}
      </AnimatePresence>
    </>
  );
}
