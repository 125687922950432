import cn from "@/utils/cn";
import { TimeSlider, VolumeSlider } from "@vidstack/react";

export function Volume({
  ...props
}: React.ComponentProps<typeof VolumeSlider.Root>) {
  return (
    <div className="pointer-events-none absolute bottom-[100%] left-1/2 max-h-0 -translate-x-1/2 rounded-md bg-black/50 p-[var(--padding-height)] opacity-0 transition-all duration-300 ease-in-out [--padding-height:7px] group-hover/volume:pointer-events-auto group-hover/volume:max-h-[calc(4em+var(--padding-height)*2)] group-hover/volume:opacity-100">
      <VolumeSlider.Root
        {...props}
        className={cn(
          "volume-slider inline-flex h-[4em] w-[5px] cursor-pointer touch-none select-none flex-col items-center outline-none transition-all duration-300 ease-in-out aria-hidden:hidden",
          props.className
        )}
        orientation="vertical"
      >
        <VolumeSlider.Track className="relative z-0 h-full w-[5px] rounded-sm bg-white/30 group-data-[focus]:ring-[3px]">
          <VolumeSlider.TrackFill className="bg-media-brand bottom-0 h-[var(--slider-fill)] w-full rounded-sm will-change-[height]" />
        </VolumeSlider.Track>

        <VolumeSlider.Preview className="pointer-events-none flex flex-col items-center opacity-0 transition-opacity duration-200 data-[visible]:opacity-100">
          <VolumeSlider.Value className="mx-[0.25em] rounded-md bg-black/75 px-[0.5em] py-px text-[13px] font-medium" />
        </VolumeSlider.Preview>
        <VolumeSlider.Thumb className="absolute bottom-[max(var(--padding-height),min(calc(100%-var(--padding-height)),calc(var(--slider-fill))))] left-1/2 z-20 h-[var(--thumb-size)] w-[var(--thumb-size)] -translate-x-1/2 translate-y-1/2 rounded-full border border-[#cacaca] bg-white ring-white/40 transition-opacity will-change-[bottom] [--thumb-size:1.5em] data-[dragging]:ring-4" />
      </VolumeSlider.Root>
    </div>
  );
}

export interface TimeSliderProps {
  thumbnails?: string;
}

export function Time({ thumbnails }: TimeSliderProps) {
  return (
    <TimeSlider.Root className="time-slider group relative mx-[0.25em] inline-flex h-[2em] w-full cursor-pointer touch-none select-none items-center outline-none">
      <TimeSlider.Chapters className="relative flex h-full w-full items-center rounded-[1px]">
        {(cues, forwardRef) =>
          cues.map((cue) => (
            <div
              className="last-child:mr-0 relative mr-[0.125em] flex h-full w-full items-center rounded-[1px]"
              style={{ contain: "layout style" }}
              key={cue.startTime}
              ref={forwardRef}
            >
              <TimeSlider.Track className="relative z-0 h-[5px] w-full rounded-sm bg-white/30 group-data-[focus]:ring-[3px]">
                <TimeSlider.Progress className="absolute h-full w-[var(--chapter-progress)] rounded-sm bg-white/50 will-change-[width]" />
                <TimeSlider.TrackFill className="absolute h-full w-[var(--chapter-fill)] rounded-sm bg-primaryAccentColor will-change-[width]" />
              </TimeSlider.Track>
            </div>
          ))
        }
      </TimeSlider.Chapters>

      <TimeSlider.Thumb className="absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-0 ring-white/40 transition-opacity will-change-[left] group-data-[active]:opacity-100 group-data-[dragging]:ring-4" />

      <TimeSlider.Preview className="pointer-events-none flex flex-col items-center opacity-0 transition-opacity duration-200 data-[visible]:opacity-100">
        {thumbnails ? (
          <TimeSlider.Thumbnail.Root
            src={thumbnails}
            className="block h-[var(--thumbnail-height)] max-h-[160px] min-h-[80px] w-[var(--thumbnail-width)] min-w-[120px] max-w-[180px] overflow-hidden border border-white bg-black"
          >
            <TimeSlider.Thumbnail.Img />
          </TimeSlider.Thumbnail.Root>
        ) : null}

        <TimeSlider.ChapterTitle className="mt-2 text-sm" />

        <TimeSlider.Value className="rounded-md bg-black/75 px-[0.5em] py-px text-[13px]" />
      </TimeSlider.Preview>
    </TimeSlider.Root>
  );
}
