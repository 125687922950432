import FullscreenWebcam from "@/components/shared/FullscreenWebcam";
import Map, { LatLng } from "@/components/shared/Map";
import { NMSReply } from "@/types/messaging";
import { isSmartTVUserAgent } from "@/utils/helpers/Utils";
import { useSketch } from "@/utils/hooks/useSketch";
import { useSelectedConversation } from "@/utils/messaging/conversation/ConversationState";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DrawOutlinedIcon from "@mui/icons-material/DrawOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { ChangeEvent, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { colors } from "../../../styles/global.styles";
import { IconButton } from "../../shared/Button";
import {
  footerDropdown,
  footerDropdownItem,
} from "../../shared/Dropdown.style";

export default function ChatFooterDropDown({
  isGroupChatCreation,
  onAttachFile,
  onSendLocation,
  isDisabled,
  reply,
}: {
  isGroupChatCreation: boolean;
  onAttachFile: (files: File[]) => void;
  onSendLocation: (coordinates: LatLng, reply?: NMSReply) => void;
  isDisabled: boolean;
  reply?: NMSReply;
}) {
  const conversation = useSelectedConversation({
    enabled: !isGroupChatCreation,
  });
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null!);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [mapOpen, setMapOpen] = useState(false);
  const { sendSketchInvite, canAcceptOrSendSketch } = useSketch();

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length === 0) return;

    onAttachFile(files);

    fileInputRef.current!.value = "";
  };

  const toggleDropdown = () => {
    setDropdownOpened((prev) => !prev);
  };

  const closeDropdown = () => {
    setDropdownOpened(false);
  };

  useOnClickOutside(
    dropdownRef,
    (e) => {
      e.stopPropagation();
      if (buttonRef.current?.contains(e.target as Node)) {
        return;
      }
      closeDropdown();
    },
    "mouseup"
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFilePickerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const openMap = () => setMapOpen(true);
  const closeMap = () => setMapOpen(false);

  const onLocationSelected = (coordinates: LatLng) => {
    closeMap();

    onSendLocation(coordinates, reply);
  };

  const [cameraOpen, setCameraOpen] = useState(false);

  const handleCameraOpen = () => {
    setCameraOpen(true);
  };

  const disabled = isDisabled || (!isGroupChatCreation && !conversation);
  const isSmartTV = isSmartTVUserAgent(navigator.userAgent);
  return (
    <div>
      <IconButton
        ref={buttonRef}
        onClick={toggleDropdown}
        disabled={disabled}
        css={{
          color: colors.secondaryTextColor,
          "&:hover": { color: colors.primaryTextColor },
        }}
      >
        <AddCircleOutlineIcon />
      </IconButton>
      <FooterDropdown
        ref={dropdownRef}
        open={dropdownOpened}
        onClick={closeDropdown}
      >
        <FooterDropdownItem onClick={handleFilePickerClick}>
          <AttachFileIcon />
          Add Attachment
        </FooterDropdownItem>
        {!isSmartTV && (
          <FooterDropdownItem onClick={handleCameraOpen}>
            <PhotoCameraOutlinedIcon />
            Take Photo
          </FooterDropdownItem>
        )}
        <FooterDropdownItem onClick={openMap}>
          <PlaceOutlinedIcon />
          Share Location
        </FooterDropdownItem>
        {/* Commenting out disabled options to not show them until they are implemented */}
        {/* <FooterDropdownItem disabled>
          <PersonOutlineOutlinedIcon />
          Share Contact
        </FooterDropdownItem> */}
        {!(conversation?.getIsGroupChat() || isGroupChatCreation) && (
          <>
            <FooterDropdownItem
              disabled={!canAcceptOrSendSketch}
              onClick={() =>
                sendSketchInvite(
                  conversation!.participants[0].getMainPhoneNumber()
                )
              }
            >
              <DrawOutlinedIcon />
              Sketch Session
            </FooterDropdownItem>
            {/* <FooterDropdownItem disabled>
              <SharedMapIcon />
              Shared Maps
            </FooterDropdownItem> */}
          </>
        )}
      </FooterDropdown>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onFileChange}
        style={{ display: "none" }}
        multiple
      />
      <Map
        onLocationSelected={onLocationSelected}
        closeMap={closeMap}
        fullScreen={true}
        open={mapOpen}
      />
      <FullscreenWebcam
        onPhoto={(file) => onAttachFile([file])}
        open={cameraOpen}
        reply={reply}
        closeCamera={() => setCameraOpen(false)}
      />
    </div>
  );
}

type FooterDropdownProps = {
  open?: boolean;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const FooterDropdown = ({
  ref,
  open,
  children,
  ...props
}: FooterDropdownProps & {
  ref?: React.RefObject<HTMLDivElement | null>;
}) => {
  return (
    <div
      ref={ref}
      css={footerDropdown}
      style={{
        display: open ? undefined : "none",
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export function FooterDropdownItem({
  children,
  ...divProps
}: {
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button type="button" css={footerDropdownItem} {...divProps}>
      {children}
    </button>
  );
}
