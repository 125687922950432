import { AnimateChangeInHeight } from "@/components/shared/AnimateChangeInHeight";
import useDragScrollInertia from "@/utils/hooks/useDragScrollInertia";
import { sanitizeMessage } from "@/utils/messaging/sanitize";
import linkifyHtml from "linkify-html";
import React, { useLayoutEffect, useMemo, useRef } from "react";
import { mediaHeightStyle } from "../RcsStyling";
import Attachment from "../components/Attachment";
import Suggestions from "../components/Suggestions";
import { isDragging, setIsDragging } from "../isDragging";
import type {
  GeneralPurposeCardCarouselMessage,
  IMedia,
  SingleContent,
} from "../typings";
import { MediaExtraInfo } from "../typings/moderatorChatbotInfo";
import {
  CardState,
  hasTextSelected,
  useCalculateAllowShrink,
  useCardState,
  useRoundBordersCss,
} from "../util/cardUtils";
import type { RichCardStyleDeclarations } from "../util/cssStyleUtils";
import { getAnimateScrollBarProps } from "../util/getAnimateScrollBarProps";
import {
  cardCarouselCss,
  cardContentCss,
  carouselTolerancePx,
  descriptionCss,
  mainContentCss,
  msgContentCss,
  titleCss,
} from "./style";

type GeneralPurposeCardCarouselProps = {
  message: GeneralPurposeCardCarouselMessage;
  stylesPending: boolean;
  stylesLoaded: RichCardStyleDeclarations | null | undefined;
  isLastMessage: boolean;
  roundBorderTop: boolean;
  roundBorderBottom: boolean;
  direction: "In" | "Out";
  mediaExtraInfo?: MediaExtraInfo;
  messageId: string;
};

export default function GeneralPurposeCardCarousel({
  message,
  stylesPending,
  stylesLoaded,
  isLastMessage,
  roundBorderTop,
  roundBorderBottom,
  direction,
  mediaExtraInfo,
  messageId,
}: GeneralPurposeCardCarouselProps) {
  const {
    cardWidth,
    descriptionFontStyle = [],
    titleFontStyle = [],
  } = message.layout;

  const titleFontClasses = useMemo(() => titleFontStyle.join(" "), []);
  const descriptionFontClasses = useMemo(
    () => descriptionFontStyle.join(" "),
    []
  );

  const maxHeightStyleName = useMemo(() => {
    let maxHeightStyleName: IMedia["height"] = "MEDIUM_HEIGHT";
    let maxHeight = 0;
    for (const { media } of message.content) {
      if (!media) continue;
      const mediaHeight = parseInt(mediaHeightStyle[media.height]) || 0;
      if (mediaHeight > maxHeight) {
        maxHeight = mediaHeight;
        maxHeightStyleName = media.height;
      }
    }
    return maxHeightStyleName;
  }, [message.content]);

  const carouselRef = useRef<HTMLDivElement>(null!);

  const titleRefs = useRef<HTMLHeadingElement[]>([]);
  //TODO FIX THIS AND REIMPLEMENT AS IT IS CAUSING ERRORS
  // oxlint-disable-next-line no-unused-vars
  const { jumpToBound } = useDragScrollInertia(
    carouselRef,
    {
      get current() {
        return isDragging;
      },
      set current(value) {
        setIsDragging(value);
      },
    },
    carouselTolerancePx,
    isLastMessage,
    {
      onDragStart: () => {
        carouselRef.current.style.cursor = "grabbing";
      },
      onDragEnd: () => {
        carouselRef.current.style.removeProperty("cursor");
      },
    }
  );

  const doesOverflowRef = useRef(false);
  const backgroundHideDelayRef = useRef(0);

  const { cardState, shrink, toggleShrink, allowShrink } = useCardState();

  useLayoutEffect(() => {
    if (!doesOverflowRef.current) {
      shrink();
    }
  }, []);

  const roundBorders = useRoundBordersCss(
    direction,
    roundBorderTop,
    roundBorderBottom
  );

  const scrollBarAnimateProps = getAnimateScrollBarProps({
    maxAlpha: 0.5,
  }).props;

  return (
    <div
      ref={carouselRef}
      css={[cardCarouselCss, msgContentCss, roundBorders]}
      style={stylesPending ? { opacity: "0.25" } : undefined}
      {...scrollBarAnimateProps}
    >
      {message.content.map((content, idx) => (
        <GeneralPurposeCarouselCard
          // eslint-disable-next-line @eslint-react/no-array-index-key
          key={idx}
          idx={idx}
          content={content}
          maxHeightStyleName={maxHeightStyleName}
          stylesLoaded={stylesLoaded}
          cardWidth={cardWidth}
          carouselRef={carouselRef}
          titleRefs={titleRefs}
          titleFontClasses={titleFontClasses}
          descriptionFontClasses={descriptionFontClasses}
          allowShrink={allowShrink}
          shrink={shrink}
          cardState={cardState}
          backgroundHideDelayRef={backgroundHideDelayRef}
          toggleShrink={toggleShrink}
          mediaExtraInfo={mediaExtraInfo}
          smallCardWidth={message.layout.cardWidth === "SMALL_WIDTH"}
          messageId={messageId}
        />
      ))}
    </div>
  );
}

type GeneralPurposeCarouselCardProps = {
  content: SingleContent;
  idx: number;
  stylesLoaded?: RichCardStyleDeclarations | null;
  maxHeightStyleName: IMedia["height"];
  cardWidth: string | undefined;
  carouselRef: React.RefObject<HTMLDivElement>;
  titleRefs: React.RefObject<HTMLHeadingElement[]>;
  titleFontClasses: string;
  descriptionFontClasses: string;
  allowShrink: () => void;
  shrink: () => void;
  cardState: CardState;
  backgroundHideDelayRef: React.RefObject<number>;
  toggleShrink: () => void;
  mediaExtraInfo?: MediaExtraInfo;
  smallCardWidth?: boolean;
  messageId: string;
};
function GeneralPurposeCarouselCard({
  content: { description, media, suggestions, title },
  idx,
  stylesLoaded,
  maxHeightStyleName,
  cardWidth,
  carouselRef,
  titleRefs,
  titleFontClasses,
  descriptionFontClasses,
  allowShrink,
  shrink,
  cardState,
  toggleShrink,
  mediaExtraInfo,
  smallCardWidth,
  messageId,
}: GeneralPurposeCarouselCardProps) {
  const {
    attachmentRef,
    titleRef,
    descriptionRef,
    suggestionsRef,
    allowShrinkRef,
  } = useCalculateAllowShrink({ allowShrink, shrink, smallCardWidth });

  const titleHTML = useMemo(
    () => title && linkifyHtml(sanitizeMessage(title), { target: "_blank" }),
    [title]
  );
  const descriptionHTML = useMemo(
    () =>
      description &&
      linkifyHtml(sanitizeMessage(description), { target: "_blank" }),
    [description]
  );

  const renderMainContent = descriptionHTML || titleHTML || suggestions;

  return (
    <div
      className={`rich-card-content ${cardWidth ?? ""}`}
      css={cardContentCss}
      style={stylesLoaded?.message}
      onClick={(e) => {
        e.stopPropagation();
        if (!allowShrinkRef.current || isDragging || hasTextSelected()) return;
        toggleShrink();
      }}
    >
      {media && (
        <Attachment
          ref={attachmentRef}
          attachment={media}
          mediaHeight={maxHeightStyleName}
          container={carouselRef}
          cardOrientation="VERTICAL"
          mediaExtraInfo={mediaExtraInfo}
          uploadId={messageId}
        />
      )}
      {renderMainContent && (
        <section css={mainContentCss}>
          {titleHTML && (
            <AnimateChangeInHeight css={{ lineHeight: "0" }}>
              <h3
                className={`title-odience-card ${titleFontClasses}`}
                css={[titleCss.main, cardState.isShrunk && titleCss.shrink]}
                ref={(h) => {
                  if (h) {
                    // eslint-disable-next-line react-compiler/react-compiler
                    titleRefs.current[idx] = h;
                  } else {
                    titleRefs.current.splice(idx, 1);
                  }
                  titleRef.current = h;
                }}
                style={stylesLoaded?.title}
                // eslint-disable-next-line @eslint-react/dom/no-dangerously-set-innerhtml
                dangerouslySetInnerHTML={{ __html: titleHTML }}
              ></h3>
            </AnimateChangeInHeight>
          )}
          {descriptionHTML && (
            <AnimateChangeInHeight css={{ lineHeight: "0" }}>
              <p
                ref={descriptionRef}
                className={descriptionFontClasses}
                css={[
                  descriptionCss.main,
                  !titleHTML && descriptionCss.noTitle,
                  !suggestions && descriptionCss.noSuggestions,
                  cardState.isShrunk && descriptionCss.shrink,
                ]}
                style={stylesLoaded?.description}
                // eslint-disable-next-line @eslint-react/dom/no-dangerously-set-innerhtml
                dangerouslySetInnerHTML={{ __html: descriptionHTML }}
              ></p>
            </AnimateChangeInHeight>
          )}
          <Suggestions
            ref={suggestionsRef}
            suggestions={suggestions}
            style={stylesLoaded?.suggestions}
            canShrink={!!description && cardState.allowShrink}
            isShrunk={cardState.isShrunk}
          />
        </section>
      )}
    </div>
  );
}
