import { colors } from "@/styles/global.styles";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import BodyPortal from "./BodyPortal";
import { IconButton } from "./Button";
import { ThreeLinesLoaderBox } from "./Loaders/ThreeLines";

const IFrameModal = ({
  url,
  onClose,
}: {
  url: string;
  onClose: VoidFunction;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <BodyPortal>
      <div
        onClick={() => onClose()}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        role="dialog"
        aria-modal="true"
        aria-label="Embedded content"
      >
        <div
          css={{
            width: "90vw",
            height: "90vh",
            borderRadius: "20px",
            overflow: "hidden",
            position: "relative",
            backgroundColor: colors.primaryBackground,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {!isLoaded && <ThreeLinesLoaderBox css={{ zIndex: 2 }} />}

          <iframe
            sandbox=""
            src={url}
            style={{ width: "100%", height: "100%", border: "none" }}
            onLoad={() => setIsLoaded(true)}
          />
          <IconButton
            onClick={() => onClose()}
            css={{
              color: colors.primaryTextColor,
              width: "3em",
              height: "3em",
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: colors.primaryBackground,
            }}
          >
            <CloseIcon
              css={{
                width: "100%",
                height: "100%",
              }}
            />
          </IconButton>
        </div>
      </div>
    </BodyPortal>
  );
};

export default IFrameModal;
