import { useEffect, useState } from "react";

function searchBreakpoint(breakpoints: Breakpoint[], width: number) {
  return breakpoints.find((x) => width >= x.value);
}

type Breakpoint<S = string> = { key: S; value: number };

/**
 * Hook for tracking breakpoints based on a container's width
 * @param breakpoints Object with breakpoint names as keys and width values
 * @param containerRef React ref to the container element
 * @returns The current breakpoint key. The width of the container is greater than or equal to that breakpoint's value (`width >= breakpoint.value`)
 */
export function useContainerBreakpoints<T extends Record<string, number>>({
  breakpoints,
  getContainer,
  onBreakpointChange,
}: {
  breakpoints: T;
  getContainer: () => HTMLElement | null;
  onBreakpointChange?: (breakpoint: Breakpoint<keyof T> | undefined) => void;
}): Breakpoint<keyof T> | undefined {
  const [breakpoint, setBreakpoint] = useState<Breakpoint<keyof T> | undefined>(
    undefined
  );

  useEffect(() => {
    const container = getContainer();
    if (!container) return;

    const breakpointEntries = Object.entries(breakpoints)
      .sort((a, b) => a[1] - b[1])
      .map(([key, value]) => ({ key, value }));

    const resizeObserver = new ResizeObserver(([entry]) => {
      console.log(
        "setting breakpoint",
        breakpointEntries,
        entry.contentRect.width,
        searchBreakpoint(breakpointEntries, entry.contentRect.width)
      );
      setBreakpoint(
        searchBreakpoint(breakpointEntries, entry.contentRect.width)
      );
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, [breakpoints]);

  useEffect(() => {
    onBreakpointChange?.(breakpoint);
  }, [breakpoint]);

  return breakpoint;
}
