import { colors } from "@/styles/global.styles";
import { NMSReply } from "@/types/messaging";
import { ls } from "@/utils/helpers/localstorage";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { AnimatePresence, motion } from "motion/react";
import { useEffect, useReducer, useRef, useState } from "react";
import toast from "react-hot-toast";
import Webcam from "react-webcam";
import { useOnClickOutside } from "usehooks-ts";
import BodyPortal from "./BodyPortal";
import { overlayTransition } from "./DraggableOverlay";
import { ThreeLinesLoader } from "./Loaders/ThreeLines";

const extension = "jpeg";
const mimetype = `image/${extension}`;

type FullscreenWebcamProps = {
  onPhoto: (file: File, reply?: NMSReply) => void;
  closeCamera: () => void;
  open: boolean;
  reply?: NMSReply;
};
function FullscreenWebcam(props: FullscreenWebcamProps) {
  return (
    <AnimatePresence>
      {props.open && <InnerFullscreenWebcam {...props} />}
    </AnimatePresence>
  );
}

function InnerFullscreenWebcam({
  onPhoto,
  closeCamera,
  reply,
}: FullscreenWebcamProps) {
  const [cameraLoaded, setCameraLoaded] = useReducer(() => true, false);
  const [cameraLoadError, setCameraLoadError] = useReducer(() => true, false);
  const sendInProgressRef = useRef(false);

  useEffect(() => {
    if (!cameraLoadError) return;

    toast.error("Unable to load camera!");
    closeCamera();
  }, [cameraLoadError]);

  const containerRef = useRef<HTMLDivElement>(null!);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const previewWrapperRef = useRef<HTMLDivElement>(null);
  const cameraRef = useRef<Webcam>(null!);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, closeCamera, "mouseup");

  const [previewPhoto, setPreviewPhoto] = useState<string | null>();

  useEffect(() => {
    if (!cameraRef.current) return;

    const closeCameraStream = () => {
      const videoStream = cameraRef.current?.stream;
      for (const track of videoStream?.getVideoTracks() || []) track.stop();
      closeCamera();
    };

    window.addEventListener("beforeunload", closeCameraStream);

    return () => {
      window.removeEventListener("beforeunload", closeCameraStream);
    };
  }, [cameraRef]);

  if (cameraLoadError) {
    return null;
  }

  const getPreviewPhoto = () => {
    const screenshot = cameraRef.current.getScreenshot();
    if (!screenshot) {
      console.error("Unable to take a screenshot of the video preview!");
      return;
    }
    setPreviewPhoto(screenshot);
  };
  const resetPreviewPhoto = () => {
    setPreviewPhoto(null);
  };

  const takePhoto = async () => {
    if (!previewPhoto) return;

    if (sendInProgressRef.current) {
      return;
    }

    sendInProgressRef.current = true;

    try {
      const imageBlob = await (await fetch(previewPhoto)).blob();
      const fileName = `photo_${ls.getUser()}_${new Date().getTime()}.${extension}`;
      const file = new File([imageBlob], fileName, { type: mimetype });
      onPhoto(file, reply);
      closeCamera();
    } catch (error) {
      console.error("Error taking photo:", error);
      toast.error("Failed to process photo");
    } finally {
      sendInProgressRef.current = false;
    }
  };

  return (
    <BodyPortal>
      <motion.div
        key="camera"
        css={{
          zIndex: "9999",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgb(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={overlayTransition}
      >
        <div
          ref={containerRef}
          style={!cameraLoaded ? { display: "none" } : undefined}
          css={{
            backgroundColor: colors.secondaryBackground,
            borderRadius: "8px",
            padding: "1.5em 0",
            position: "absolute",
            width: "80vw",
            maxWidth: "min(90vw, 70em)",
            display: "flex",
            flexDirection: "column",
            gap: "1.5em",
            alignItems: "center",
            animation: `${keyframes({
              "0%": {
                opacity: "0",
              },
              "100%": {
                opacity: "1",
              },
            })} 0.25s ease`,
          }}
        >
          <div
            css={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1.5em",
            }}
          >
            <h2
              css={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.5em",
              }}
            >
              Take Photo
            </h2>
            <WebcamButton
              onClick={closeCamera}
              css={{ backgroundColor: "transparent" }}
            >
              <CloseIcon />
            </WebcamButton>
          </div>

          {/* Camera/Preview Container */}
          <div
            ref={wrapperRef}
            css={{
              position: "relative",
              width: "100%",
              boxShadow: "0 0 6px 0 rgb(0,0,0,0.5)",
              overflow: "hidden",
              transition: `opacity 0.25s ease`,
            }}
          >
            <Webcam
              ref={cameraRef}
              css={{
                width: "100%",
              }}
              style={{
                opacity: previewPhoto ? 0 : 1,
                transitionDelay: previewPhoto ? "0.25s" : "0s",
              }}
              onUserMedia={setCameraLoaded}
              onUserMediaError={setCameraLoadError}
              screenshotFormat={mimetype}
              screenshotQuality={0.9}
              videoConstraints={{
                aspectRatio: {
                  ideal: 4 / 3,
                },
              }}
            />
            <AnimatePresence>
              {previewPhoto && (
                <motion.div
                  key="preview"
                  ref={previewWrapperRef}
                  css={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={overlayTransition}
                >
                  <img src={previewPhoto} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Control Buttons */}
          <div
            ref={buttonContainerRef}
            css={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: "1em",
              padding: "0 1.5em",
            }}
          >
            {!previewPhoto ? (
              <WebcamButton
                css={{
                  backgroundColor: colors.primaryAccentColor,
                  margin: "0 auto",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  getPreviewPhoto();
                }}
              >
                <PhotoCameraIcon />
              </WebcamButton>
            ) : (
              <>
                <WebcamButton
                  onClick={(e) => {
                    e.stopPropagation();
                    resetPreviewPhoto();
                  }}
                  css={{ backgroundColor: colors.secondaryBackgroundLighter }}
                >
                  <span css={{ marginRight: "0.5em" }}>Retake</span>
                  <CloseIcon />
                </WebcamButton>
                <WebcamButton
                  onClick={(e) => {
                    e.stopPropagation();
                    void takePhoto();
                  }}
                  css={{ backgroundColor: colors.primaryAccentColor }}
                >
                  <span css={{ marginRight: "0.5em" }}>Use</span>
                  <CheckIcon />
                </WebcamButton>
              </>
            )}
          </div>
        </div>
        {!cameraLoaded && <ThreeLinesLoader />}
      </motion.div>
    </BodyPortal>
  );
}

const WebcamButton = styled.button({
  width: "auto",
  minWidth: "2.75em",
  height: "2.75em",
  padding: "0 0.75em",
  borderRadius: "6px",
  background: colors.secondaryBackground,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
});

export default FullscreenWebcam;
