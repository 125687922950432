import twitterSpriteSheetURL from "@/assets/emoji/twitter-emoji-spritesheet-64.webp";
import { colors } from "@/styles/global.styles";
import { preloadImage } from "@/utils";
import { ease } from "@/utils/ease";
import mergeRefs2 from "@/utils/helpers/mergeRefs2";
import twitterData from "@emoji-mart/data/sets/15/twitter.json";
import Picker from "@emoji-mart/react";
import { css, SerializedStyles } from "@emotion/react";
import CircularProgress from "@mui/material/CircularProgress";
import Color from "color";
import { AnimatePresence, motion } from "motion/react";
import React, { useEffect, useMemo, useRef, useState } from "react";

type EmojiPickerProps = {
  handleEmojiClick: (emoji: string) => void;
  visible: boolean;
  uiMode?: "MESSAGE_REACTION" | "SEND_MESSAGE";
};

export default function EmojiPicker({
  ref,
  ...props
}: EmojiPickerProps & {
  ref?: React.RefObject<HTMLDivElement | null>;
}) {
  const emojiPickerRef = useRef<HTMLDivElement>(null!);
  useAddEmojiPickerStyles(
    emojiPickerRef,
    `.search input[type="search"]:focus { box-shadow: none; }
    .scroll::-webkit-scrollbar {
      width: 14px;
    } 
    .scroll::-webkit-scrollbar-thumb {
      background-color: rgb(140, 143, 146, var(--scrollbar-alpha)) !important;
      border-radius: 14px;
    }
    .scroll::-webkit-scrollbar-track {
        background-color: rgb(46, 50, 55, var(--scrollbar-alpha));
        border-radius: 14px;
    }`
  );

  const loaded = useEmojiSpritesheetLoaded();

  const pickerProperties: Parameters<typeof Picker>[0] = {
    emojiButtonSize: 46,
    emojiSize: 32,
    autoFocus: true,
    dynamicWidth: true,
  };

  let wrapperCssCustom: SerializedStyles | null = null;

  if (props.uiMode === "MESSAGE_REACTION") {
    Object.assign(pickerProperties, {
      emojiButtonSize: 35,
      emojiSize: 20,
      searchPosition: "none",
      perLine: 7,
      autoFocus: false,
      dynamicWidth: false,
    });

    wrapperCssCustom = css(
      {
        width: "auto !important",
        // relative since the reaction picker should be rendered in a Popover (floating ui component)
        position: "relative",
        paddingBottom: "unset",
      },
      `em-emoji-picker {
        width: auto !important;
        max-width: auto !important;
        height: 20vh !important;
        min-height: auto !important;
        max-height: auto !important;
      }`
    );
  }

  useAutoFocus(emojiPickerRef, props.visible);

  return (
    <div
      ref={mergeRefs2(() => [ref, emojiPickerRef])}
      id="EmojiPicker"
      css={[
        wrapperCss,
        wrapperCssCustom,
        !loaded && "em-emoji-picker { --color-border: transparent }",
      ]}
      style={{ display: props.visible ? "block" : "none" }}
    >
      <Picker
        onEmojiSelect={(emoji: { native: string }) => {
          props.handleEmojiClick(emoji.native);
        }}
        data={twitterData}
        set="twitter"
        navPosition="bottom"
        previewPosition="none"
        reactionsDefaultOpen={true}
        getSpritesheetURL={() => twitterSpriteSheetURL}
        {...pickerProperties}
      />
      <LoadingIndicator show={!loaded} />
    </div>
  );
}

function getEmojiPickerShadowRoot(
  emojiPickerRef: React.RefObject<HTMLElement>
) {
  return emojiPickerRef.current.querySelector("em-emoji-picker")?.shadowRoot;
}

function useAddEmojiPickerStyles(
  emojiPickerRef: React.RefObject<HTMLElement>,
  styles: string
) {
  useEffect(() => {
    const shadowRoot = getEmojiPickerShadowRoot(emojiPickerRef);
    if (!shadowRoot) {
      console.warn("Emoji picker shadow root not found");
      return;
    }

    const sheet = new CSSStyleSheet();
    sheet.replaceSync(styles);

    const indexAddedAt = shadowRoot.adoptedStyleSheets.length;
    shadowRoot.adoptedStyleSheets.push(sheet);

    return () => {
      shadowRoot.adoptedStyleSheets.splice(indexAddedAt, 1);
    };
  }, [emojiPickerRef, styles]);
}

function useAutoFocus(
  emojiPickerRef: React.RefObject<HTMLElement>,
  visible: boolean
) {
  useEffect(() => {
    if (!visible) return;

    const shadowRoot = getEmojiPickerShadowRoot(emojiPickerRef);
    if (!shadowRoot) {
      console.warn("Emoji picker shadow root not found");
      return;
    }

    const t = setTimeout(() => {
      const input = shadowRoot.querySelector("input");
      if (!input) {
        console.warn("useAutoFocus Emoji picker input not found");
        return;
      }

      input.focus();
    });

    return () => {
      clearTimeout(t);
    };
  }, [emojiPickerRef, visible]);
}

function useEmojiSpritesheetLoaded() {
  const delayBeforeShowLoading = 150;

  // assume the image is preloaded
  const [isLoaded, setIsLoaded] = useState(true);

  const preloadImagePromise = useMemo(
    () => preloadImage(twitterSpriteSheetURL),
    []
  );

  useEffect(() => {
    let isMounted = true;

    const t = setTimeout(setIsLoaded, delayBeforeShowLoading, false);

    void (async () => {
      await preloadImagePromise;
      if (!isMounted) return;
      clearTimeout(t);
      setIsLoaded(true);
    })();

    return () => {
      isMounted = false;
      clearTimeout(t);
    };
  }, [preloadImagePromise]);

  return isLoaded;
}

const wrapperCss = css(
  {
    "--paddingBottom": "0.5em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "100%",
    zIndex: 100,
    paddingBottom: "var(--paddingBottom)",
    width: "fit-content",
    // sizing for smaller screens
    "@media (max-width: 1280px)": {
      transform: "translateX(0)",
      "& em-emoji-picker": {
        width: "100% !important",
        maxWidth: "100% !important",
      },
    },
    // weird wrapper div around em-emoji-picker
    "& > div": {
      width: "100%",
      height: "100%",
    },
    // allow clicks through the loading indicator
    /* "&:hover > .loading-indicator": {
      pointerEvents: "none", 
      opacity: "0.5 !important",
      "& > shadow": {
        opacity: "0 !important",
      },
    }, */
  },
  `em-emoji-picker {
    --border-radius: 16px;
    --category-icon-size: 24px;
    --color-border-over: rgba(0, 0, 0, 0.1);
    --color-border: rgba(0, 0, 0, 0.15);
    --font-size: 18px;
    --rgb-accent: ${new Color(colors.primaryAccentColor)
      .rgb()
      .array()
      .join(", ")};
    --rgb-background: ${new Color(colors.secondaryBackground)
      .rgb()
      .array()
      .join(", ")};
    --rgb-color: ${new Color(colors.secondaryTextColor)
      .rgb()
      .array()
      .join(", ")};
    --rgb-input: ${new Color(colors.secondaryBackgroundLighter)
      .rgb()
      .array()
      .join(", ")};
    --shadow: 0 4px 8px 0px rgb(0,0,0,0.25);

    width: 30vw;
    max-width: 420px;
    height: 50vh;
    min-height: 400px;
    max-height: 800px;
  }`
);

function LoadingIndicator({ show }: { show: boolean }) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="loading-indicator"
          css={{
            position: "absolute",
            width: "100%",
            height: "calc(100% - var(--paddingBottom))",
            top: "50%",
            left: "50%",
            translate: "-50% -50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5em",
            zIndex: "100",
            textShadow: "0 0 4px black",
            transition: "opacity 0.25s ease",
            userSelect: "none",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.35, ease }}
        >
          {/* background shadow */}
          <div
            css={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "calc(100% - var(--paddingBottom))",
              background: "rgba(0,0,0,0.35)",
              borderRadius: "16px",
              transition: "opacity 0.25s ease",
            }}
          />

          <span css={{ zIndex: "1" }}>Loading emojis...</span>
          <CircularProgress
            css={{
              color: colors.primaryAccentColor,
            }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
