import Timer from "@/components/shared/Timer";
import { colors } from "@/styles/global.styles";
import { convertWebMAudioToMP3, loadFFmpeg } from "@/utils/helpers/audio";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import IconButton from "@mui/material/IconButton";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { VoiceVisualizer, useVoiceVisualizer } from "react-voice-visualizer";

enum VoiceNoteState {
  RECORDING,
  STOPPED,
  PLAYING,
  PAUSED,
}

function useRecordingConversion({
  onConversion,
  recording,
}: {
  onConversion: (blob: Blob) => void;
  recording: Blob | null;
}) {
  const ffmpegPromiseRef = useRef<Promise<FFmpeg | undefined>>(null);
  useEffect(() => {
    // will run if it's shown for the first time
    if (!ffmpegPromiseRef.current) {
      ffmpegPromiseRef.current = loadFFmpeg();
    }
  }, []);

  // will run on stopped state
  useEffect(() => {
    if (!recording) return;

    void (async () => {
      if (!recording) {
        console.warn("no recorded blob", recording);
        return;
      }

      const ffmpeg = await ffmpegPromiseRef.current;
      if (!ffmpeg) {
        console.error(
          "FFmpeg not loaded! Voice recording was not converted to MP3."
        );
        return;
      }

      const voiceNoteMP3 = await convertWebMAudioToMP3(ffmpeg, recording);

      onConversion(voiceNoteMP3);
    })();
  }, [recording]);
}

const VoiceNote = ({
  autoStartRecording = true,
  height,
  onVoiceNoteRecorded,
}: {
  autoStartRecording?: boolean;
  height: number | string;
  onVoiceNoteRecorded: (blob: Blob) => void;
}) => {
  const recorderControls = useVoiceVisualizer();

  const state = recorderControls.isRecordingInProgress
    ? VoiceNoteState.RECORDING
    : !recorderControls.isPausedRecordedAudio &&
        recorderControls.isAvailableRecordedAudio
      ? VoiceNoteState.PLAYING
      : recorderControls.isPausedRecording
        ? VoiceNoteState.PAUSED
        : VoiceNoteState.STOPPED;

  const handleNextState = () => {
    switch (state) {
      case VoiceNoteState.RECORDING:
        recorderControls.stopRecording();
        break;
      case VoiceNoteState.STOPPED:
      case VoiceNoteState.PAUSED:
      case VoiceNoteState.PLAYING:
        recorderControls.togglePauseResume();
        break;
    }
  };

  const recordingStarted = useRef(false);
  useEffect(() => {
    // seems like there is an internal library bug that causes the recording to start and not stop twice.
    // the cleanup function should be called when the component is unmounted after the first strict mode render to end the media stream, but the stream isn't ended.
    if (!recordingStarted.current && autoStartRecording) {
      recorderControls.startRecording();
      recordingStarted.current = true;
    }
  }, []);

  useRecordingConversion({
    onConversion: onVoiceNoteRecorded,
    recording: recorderControls.recordedBlob,
  });

  const voiceNoteWrapperRef = useRef<HTMLDivElement>(null);
  const [voiceNoteInnerWidth, setVoiceNoteInnerWidth] = useState(0);
  useLayoutEffect(() => {
    if (!voiceNoteWrapperRef.current) return;

    const setWidth = () => {
      if (!voiceNoteWrapperRef.current) return;

      const width = voiceNoteWrapperRef.current.clientWidth;
      // The library we use starts the audio wave from the middle of the component.
      // This trick double the size to make the middle be at the right end in order to have the wave starts from it.
      setVoiceNoteInnerWidth(width);
    };

    setWidth();

    const resizeObserver = new ResizeObserver(setWidth);
    resizeObserver.observe(voiceNoteWrapperRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  useLayoutEffect(() => {
    const voiceNoteDiv = voiceNoteWrapperRef.current?.firstElementChild as
      | HTMLElement
      | null
      | undefined;
    if (!voiceNoteDiv) return;

    voiceNoteDiv.style.maxWidth = "0";
  });

  let voiceNoteIcon: React.ReactNode;
  switch (state) {
    case VoiceNoteState.RECORDING:
      voiceNoteIcon = <StopCircleIcon />;
      break;

    case VoiceNoteState.STOPPED:
    case VoiceNoteState.PAUSED:
      voiceNoteIcon = <PlayCircleOutlineIcon />;
      break;

    case VoiceNoteState.PLAYING:
      voiceNoteIcon = <PauseCircleOutlineIcon />;
      break;
  }

  const time = new Date(
    state === VoiceNoteState.RECORDING
      ? recorderControls.recordingTime
      : /* currentAudioTime is in seconds, oddly */
        recorderControls.currentAudioTime * 1000
  );
  time.setHours(0);

  return (
    <>
      <IconButton
        style={{
          color:
            state === VoiceNoteState.RECORDING ||
            state === VoiceNoteState.PLAYING
              ? colors.primaryAccentColor
              : colors.secondaryTextColor,
        }}
        onClick={handleNextState}
      >
        {voiceNoteIcon}
      </IconButton>

      <Timer time={time} />

      <div
        ref={voiceNoteWrapperRef}
        css={{
          overflow: "hidden",
          display: "flex",
          flex: "1",
          flexDirection: "row",
          alignItems: "center",
          userSelect: "none",
        }}
      >
        <VoiceVisualizer
          controls={recorderControls}
          isControlPanelShown={false}
          isDefaultUIShown={false}
          isProgressIndicatorTimeShown={false}
          isProgressIndicatorShown={true}
          width={
            voiceNoteInnerWidth * (state === VoiceNoteState.RECORDING ? 1.9 : 1)
          }
          height={height}
        />
      </div>
    </>
  );
};

export default VoiceNote;
