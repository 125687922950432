// eslint-disable no-await-in-loop
import { nanoid } from "nanoid";
import { useLayoutEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useUnmount } from "usehooks-ts";
import { proxy, ref } from "valtio";
import { sleep } from "..";
import { cancel, fetchWithDigestAuthentication } from "../helpers/fileUtils";

export type FileWithProgress = {
  file: File;
  progress: number;
  uploadId: string;
};
export type FilePreviewRes = ReturnType<typeof useFilePreview>;
export function useFilePreview({
  key,
}: { key?: string | number | null | undefined } = {}) {
  const pendingFilesProxy = useMemo(() => proxy<FileWithProgress[]>([]), []);

  const clear = () => {
    pendingFilesProxy.splice(0, pendingFilesProxy.length);
  };

  useLayoutEffect(() => {
    clear();
  }, [clear, key]);

  const handleRemoveFile = (fileToRemove: File) => {
    for (let i = 0; i < pendingFilesProxy.length; i++) {
      if (pendingFilesProxy[i].file === fileToRemove) {
        cancel(pendingFilesProxy[i].uploadId);
        pendingFilesProxy.splice(i, 1);
        break;
      }
    }
  };

  const handleAddFiles = (newFiles: File[]) => {
    const uniqueNewFilesWithProgress: FileWithProgress[] = newFiles
      .filter(
        (newFile) =>
          !pendingFilesProxy.some(
            ({ file: existingFile }) => existingFile.name === newFile.name
          )
      )
      .map((file) =>
        ref(
          proxy({
            file: ref(file),
            progress: 0,
            uploadId: nanoid(),
          })
        )
      );

    if (uniqueNewFilesWithProgress.length > 0) {
      pendingFilesProxy.push(...uniqueNewFilesWithProgress);
    } else {
      toast.error("File already added");
    }

    // upload files here
    void (async () => {
      for (const fileWithProgress of uniqueNewFilesWithProgress) {
        console.log("Uploading ", fileWithProgress.file);

        try {
          await fetchWithDigestAuthentication(
            "POST",
            fileWithProgress.file,
            true,
            (progress) => {
              fileWithProgress.progress = progress;
            },
            fileWithProgress.uploadId
          );
        } catch (error) {
          console.error("Error uploading", fileWithProgress.file, error);
        }

        await sleep(10);
      }
    })();
  };

  useUnmount(() => {
    for (const { uploadId } of pendingFilesProxy) {
      cancel(uploadId);
    }
  });

  return {
    pendingFilesProxy,
    handleAddFiles,
    handleRemoveFile,
    clear,
  } as const;
}
