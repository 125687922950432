import { Controls, Gesture, Time } from "@vidstack/react";

import * as Buttons from "./Buttons";
import * as Sliders from "./Sliders";

export function VideoLayout() {
  return (
    <>
      <Gestures />
      <Controls.Root
        className={`video-controls absolute inset-0 z-10 flex h-full w-full flex-col justify-end text-nowrap bg-gradient-to-t from-black/10 to-transparent opacity-0 transition-opacity media-controls:opacity-100`}
      >
        <Controls.Group className="flex w-full items-center px-[0.5em]">
          <Sliders.Time />
        </Controls.Group>
        <Controls.Group className="-mt-0.5 flex w-full items-center px-[0.5em] pb-[0.5em] [filter:drop-shadow(0px_0px_2px_rgba(0,0,0,0.5))]">
          <Buttons.Play tooltipPlacement="top start" />
          <div className="volume-control group/volume relative flex items-center">
            <Buttons.Mute tooltipPlacement="top" />
            <Sliders.Volume />
          </div>
          <TimeGroup />
          <div className="flex-1" />
          <Buttons.Fullscreen tooltipPlacement="top end" />
        </Controls.Group>
      </Controls.Root>
    </>
  );
}

function Gestures() {
  return (
    <>
      <Gesture
        className="absolute inset-0 z-0 block h-full w-full"
        event="pointerup"
        action="toggle:paused"
      />
      <Gesture
        className="absolute inset-0 z-0 block h-full w-full"
        event="dblpointerup"
        action="toggle:fullscreen"
      />
      <Gesture
        className="absolute left-0 top-0 z-10 block h-full w-1/5"
        event="dblpointerup"
        action="seek:-5"
      />
      <Gesture
        className="absolute right-0 top-0 z-10 block h-full w-1/5"
        event="dblpointerup"
        action="seek:5"
      />
    </>
  );
}

function TimeGroup() {
  return (
    <div className="time ml-[0.375em] flex items-center text-[1.15em] font-medium leading-none">
      <Time type="current" />
      <div className="mx-1 text-white/80">/</div>
      <Time type="duration" />
    </div>
  );
}
