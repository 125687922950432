import {
  FullscreenButton,
  MuteButton,
  PlayButton,
  Tooltip,
  useMediaState,
  type TooltipPlacement,
} from "@vidstack/react";

// import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
// import CircularProgress from "@mui/material/CircularProgress";
import PauseIcon from "@heroicons/react/24/solid/PauseIcon";
import PlayIcon from "@heroicons/react/24/solid/PlayIcon";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import VolumeLowIcon from "@mui/icons-material/VolumeDownRounded";
import MuteIcon from "@mui/icons-material/VolumeOffRounded";
import VolumeHighIcon from "@mui/icons-material/VolumeUpRounded";

export interface MediaButtonProps {
  tooltipPlacement: TooltipPlacement;
}

const buttonClass =
  "group relative inline-flex h-[2.5em] w-[2.5em] cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-white/20 data-[focus]:ring-4";

const tooltipClass =
  "animate-out fade-out slide-out-to-bottom-2 data-[visible]:animate-in data-[visible]:fade-in data-[visible]:slide-in-from-bottom-4 z-10 rounded-sm bg-black/90 px-2 py-0.5 text-sm font-medium text-white parent-data-[open]:hidden";

export function Play({ tooltipPlacement }: MediaButtonProps) {
  const isPaused = useMediaState("paused");
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <PlayButton className={buttonClass}>
          {isPaused ? (
            <PlayIcon className="h-[2em] w-[2em]" fontSize="inherit" />
          ) : (
            <PauseIcon className="h-[2em] w-[2em]" fontSize="inherit" />
          )}
        </PlayButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipClass} placement={tooltipPlacement}>
        {isPaused ? "Play" : "Pause"}
      </Tooltip.Content>
    </Tooltip.Root>
  );
}

export function Mute({ tooltipPlacement }: MediaButtonProps) {
  const volume = useMediaState("volume"),
    isMuted = useMediaState("muted");
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <MuteButton className={buttonClass}>
          {isMuted || volume == 0 ? (
            <MuteIcon className="!h-[2em] !w-[2em]" fontSize="inherit" />
          ) : volume < 0.5 ? (
            <VolumeLowIcon className="!h-[2em] !w-[2em]" fontSize="inherit" />
          ) : (
            <VolumeHighIcon className="!h-[2em] !w-[2em]" fontSize="inherit" />
          )}
        </MuteButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipClass} placement={tooltipPlacement}>
        {isMuted ? "Unmute" : "Mute"}
      </Tooltip.Content>
    </Tooltip.Root>
  );
}

export function Fullscreen({ tooltipPlacement }: MediaButtonProps) {
  const isFullscreen = useMediaState("fullscreen");
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <FullscreenButton className={buttonClass}>
          {isFullscreen ? (
            <FullscreenExitIcon
              className="!h-[2em] !w-[2em]"
              fontSize="inherit"
            />
          ) : (
            <FullscreenIcon className="!h-[2em] !w-[2em]" fontSize="inherit" />
          )}
        </FullscreenButton>
      </Tooltip.Trigger>
      <Tooltip.Content className={tooltipClass} placement={tooltipPlacement}>
        {isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
      </Tooltip.Content>
    </Tooltip.Root>
  );
}
