// TODO
/* eslint-disable react-compiler/react-compiler */
/* eslint-disable react-hooks/rules-of-hooks */
import AudioPlayer, { AudioPlayerAPI } from "@/components/shared/AudioPlayer";
import BodyPortal from "@/components/shared/BodyPortal";
import { IconButton } from "@/components/shared/Button";
import Map, { LatLng } from "@/components/shared/Map";
import VideoPlayer from "@/components/shared/VideoPlayer/VideoPlayer";
import cn from "@/utils/cn";
import { atoms } from "@/utils/helpers/atoms";
import { cancel } from "@/utils/helpers/fileUtils";
import { formatFileSizeToHumanReadable } from "@/utils/messaging/conversation/conversationUtils/";
import { queryClient } from "@/utils/queryClient";
import { keyframes } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import Error from "@mui/icons-material/Error";
import GenericFile from "@mui/icons-material/InsertDriveFileOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { milliseconds } from "date-fns/milliseconds";
import { useAtom } from "jotai";
import mergeRefs from "merge-refs";
import { AnimatePresence, motion } from "motion/react";
import React, {
  ReactNode,
  RefObject,
  memo,
  useEffect,
  useReducer,
  useRef,
  useState,
  type CSSProperties,
} from "react";
import { useUnmount } from "usehooks-ts";
import { colors } from "../../../../styles/global.styles";
import {
  mediaAspectRatio,
  mediaHeightStyle,
  mediaMaxHeightVertical,
} from "../RcsStyling";
import { isDragging } from "../isDragging";
import type { ChatMessageStatus, IMedia } from "../typings";
import { MediaExtraInfo } from "../typings/moderatorChatbotInfo";

const OPEN_IN_NEW_TAB_CONTENT_TYPES = ["application/pdf"];

function UploadProgressSpinner({
  progress,
  uploadId,
  onCancel,
}: {
  progress: number;
  uploadId: string;
  onCancel?: (uploadId: string) => void;
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        onClick={() => {
          cancel(uploadId);
          onCancel?.(uploadId);
        }}
        style={{
          cursor: "pointer",
          zIndex: "2",
          position: "absolute",
          width: "40%",
          height: "40%",
          backgroundColor: colors.primaryAccentColor,
        }}
      />
      {progress === -1 ? (
        <ErrorOverlay />
      ) : (
        <CircularProgress
          variant="determinate"
          value={progress}
          css={{ color: colors.primaryTextColor }}
        />
      )}
    </div>
  );
}

function LoopProgressSpinner() {
  return <CircularProgress css={{ color: colors.primaryTextColor }} />;
}

function ProgressSpinner({
  progress,
  uploadId,
  onCancel,
}: {
  progress: number | boolean | undefined;
  uploadId?: string;
  onCancel?: (uploadId: string) => void;
}) {
  if (!uploadId) return null;

  const upload = typeof progress == "number" && progress < 100;
  const loop = typeof progress == "boolean" && progress;

  return upload || loop ? (
    <div
      css={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "2",
      }}
    >
      {upload ? (
        <UploadProgressSpinner
          progress={progress}
          uploadId={uploadId}
          onCancel={onCancel}
        />
      ) : (
        <LoopProgressSpinner />
      )}
    </div>
  ) : null;
}

function ErrorOverlay() {
  return (
    <div
      css={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: "none",
        width: "2em",
        height: "2em",
      }}
    >
      <Error css={{ color: colors.primaryAccentColor }} />
    </div>
  );
}

type MediaProps = {
  style?: CSSProperties;
  children: ReactNode;
  isFailed: boolean;
  progress: number | boolean | undefined;
  uploadId?: string;
  onCancel?: (uploadId: string) => void;
};

function Media({
  ref,
  children,
  style,
  isFailed,
  progress: uploadProgress,
  uploadId,
  onCancel,
}: MediaProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  const mediaRef = useRef<HTMLDivElement>(null!);

  const fade =
    (typeof uploadProgress == "number" && uploadProgress < 100) ||
    (typeof uploadProgress == "boolean" && uploadProgress);

  return (
    <>
      {isFailed && <ErrorOverlay />}
      <ProgressSpinner
        progress={uploadProgress}
        uploadId={uploadId}
        onCancel={onCancel}
      />
      <div
        ref={mergeRefs(ref, mediaRef)}
        className={cn(
          "media relative leading-[0] transition-opacity duration-[0.35s] ease-out",
          fade && "opacity-50"
        )}
        style={style}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </>
  );
}

function FullscreenViewer({
  isFullScreen,
  setIsFullScreen,
  children,
}: {
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  children: ReactNode;
}) {
  return (
    <AnimatePresence>
      {isFullScreen && (
        <BodyPortal>
          <motion.div
            css={{
              position: "fixed",
              inset: "0",
              zIndex: "999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(0, 0, 0, 0.5)",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIsFullScreen(false);
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
          >
            <motion.div
              css={{
                position: "relative",
                width: "fit-content",
                height: "fit-content",
                overflow: "hidden",
                borderRadius: "var(--border-radius, 12px)",
                "& *:not(.close)": {
                  minWidth: "auto !important",
                  minHeight: "auto !important",
                  width: "auto !important",
                  height: "auto !important",
                  maxWidth: "90vw !important",
                  maxHeight: "90vh !important",
                },
                "&:hover .close": {
                  opacity: "1",
                },
              }}
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 1, transition: { duration: 0.2 } }}
            >
              <IconButton
                className="close"
                onClick={() => setIsFullScreen(false)}
                css={{
                  position: "absolute",
                  top: "0.5em",
                  right: "0.5em",
                  color: colors.primaryTextColor,
                  background: colors.primaryBackgroundLighter,
                  zIndex: "1",
                  opacity: "0",
                  transition: "opacity 0.25s ease",
                }}
              >
                <CloseIcon />
              </IconButton>
              {children}
            </motion.div>
          </motion.div>
        </BodyPortal>
      )}
    </AnimatePresence>
  );
}

interface AttachmentProps {
  attachment: IMedia;
  mediaHeight?: IMedia["height"];
  /**
   * container to set a property containing the height of the media element
   */
  container?: RefObject<HTMLElement | null>;
  mediaOnly?: boolean;
  uploadProgress?: number;
  messageStatus?: ChatMessageStatus;
  cardOrientation?: "HORIZONTAL" | "VERTICAL";
  mediaExtraInfo?: MediaExtraInfo;
  uploadId?: string;
  onCancel?: (uploadId: string) => void;
}

const mediaAlignmentMap = {
  bestfit_height_left: "left",
  bestfit_height_right: "right",
  bestfit_width_center: "center",
  bestfit_width_top: "top",
  bestfit_width_bottom: "bottom",
};

const toObjectPosition = (mediaExtraInfo?: MediaExtraInfo) => {
  if (!mediaExtraInfo?.mediaAlignment) return undefined;
  return mediaAlignmentMap[mediaExtraInfo?.mediaAlignment];
};

const Attachment = ({
  ref,
  attachment,
  mediaHeight,
  container,
  mediaOnly: mediaOnlyMessage = false,
  uploadProgress,
  messageStatus,
  cardOrientation,
  mediaExtraInfo,
  uploadId,
  onCancel,
}: AttachmentProps & {
  ref?: React.Ref<HTMLDivElement>;
}) => {
  const {
    mediaContentType: type,
    mediaUrl: url,
    height,
    thumbnailUrl,
    contentDescription,
    mediaFileSize,
  } = attachment;

  if (!type) {
    return (
      <a href={url} className="shrink" style={{ maxWidth: "80%" }}>
        {url}
      </a>
    );
  }

  const [mapOpen, setMapOpen] = useState(false);

  const position = toObjectPosition(mediaExtraInfo);
  const mediaCss: CSSProperties = mediaOnlyMessage
    ? {
        width: "200px",
        height: "200px",
        objectFit: "cover",
      }
    : {
        objectFit: position === "center" ? "contain" : "cover",
        objectPosition: position,
        minHeight: mediaHeight
          ? mediaHeightStyle[mediaHeight]
          : (mediaHeightStyle[height] ?? "100%"),
        ...(cardOrientation === "VERTICAL"
          ? {
              maxHeight: mediaMaxHeightVertical,
            }
          : {
              height: mediaHeight
                ? mediaHeightStyle[mediaHeight]
                : (mediaHeightStyle[height] ?? "100%"),
            }),
        aspectRatio: mediaHeight
          ? mediaAspectRatio[mediaHeight]
          : mediaAspectRatio[height],
      };

  const otherCss: CSSProperties = {
    padding: "0.3em",
    cursor: "pointer",
    background: "none",
    width: "auto",
  };

  useEffect(() => {
    if (!container?.current) return;

    container.current.style.setProperty(
      "--media-height",
      mediaCss.height as string
    );
    if (type.includes("application")) {
      container.current.style.setProperty("min-height", "0");
    } else if (type.includes("audio")) {
      container.current.style.setProperty("background", "none");
      container.current.style.setProperty("min-height", "0");
      container.current.style.setProperty("min-width", "20em");
    }
  }, []);

  const isUploading = uploadProgress !== undefined && uploadProgress < 100;
  const isFailed = (messageStatus && messageStatus === "failed") as boolean;
  const cssIfFailOrProgress = (isUploading || isFailed) && {
    opacity: "0.2 !important",
  };

  const fileName = contentDescription ?? "File";

  const downloadFile = () => {
    // Some content type could be displayed in the browser (like pdf), we force to open in a new tab
    if (
      url.startsWith("http") &&
      OPEN_IN_NEW_TAB_CONTENT_TYPES.includes(type)
    ) {
      window.open(url, "_blank");
    } else {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (type.includes("image")) {
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgLoadErr, setImgLoadErr] = useState(false);
    const [mediaUrl, setMediaUrl] = useState(url);

    const [isFullScreen, setIsFullScreen] = useState(false);

    const fetchedRef = useRef(false);

    const isHeicImage = type.includes("image/heic");

    useEffect(() => {
      if (!imgLoadErr) return;
      if (!isHeicImage) return;

      console.log("Error loading image, retrying using heic convert", url);

      if (fetchedRef.current) return;
      fetchedRef.current = true;

      const abortController = new AbortController();

      const fetchMedia = async () => {
        try {
          const res = await queryClient.fetchQuery({
            queryKey: ["heicConvert", url],
            queryFn: async () => {
              const heicConvertImport = import(
                "@/utils/messaging/conversation/conversationUtils/heicConvert"
              );

              const imageDataAbortController = new AbortController();
              const imageDataRes = await fetch(url, {
                credentials: "include",
                signal: imageDataAbortController.signal,
              });

              const contentType = imageDataRes.headers.get("content-type");
              if (contentType !== "image/heic") {
                const message = `Invalid image type: ${contentType}`;
                imageDataAbortController.abort(message);
                throw new window.Error(message);
              }

              const imageData = await imageDataRes.arrayBuffer();

              const { heicConvert } = await heicConvertImport;

              const convertTimeMarker = `Converting HEIC to PNG ${url.substring(20)}`;
              console.time(convertTimeMarker);
              const res = await heicConvert(
                {
                  buffer: imageData,
                  format: "PNG",
                },
                abortController.signal
              );
              console.timeEnd(convertTimeMarker);
              return res;
            },
            staleTime: milliseconds({ hours: 1 }),
            gcTime: milliseconds({ hours: 1 }),
            retry: false,
          });

          if ("error" in res) {
            console.error("Error converting heic to png", res.error);
            return;
          }
          const imageBlob = new Blob([res.imageData], { type: "image/png" });

          const blobUrl = URL.createObjectURL(imageBlob);
          setMediaUrl(blobUrl);
          // reset states
          setImgLoaded(false);
          setImgLoadErr(false);
        } catch (error) {
          console.error("Error fetching media:", error);
        }
      };

      void fetchMedia();

      return () => {
        abortController.abort();
      };
    }, [url, imgLoadErr]);

    useUnmount(() => {
      if (isHeicImage && mediaUrl.startsWith("blob:")) {
        URL.revokeObjectURL(mediaUrl);
      }
    });

    const mediaRef = useRef<HTMLDivElement>(null!);

    const loadThumbnail =
      thumbnailUrl &&
      !imgLoaded &&
      !isUploading &&
      (mediaFileSize > 200_000 || !mediaFileSize);

    const imageComponent = (
      <Media
        ref={mergeRefs(ref, mediaRef)}
        style={mediaCss}
        isFailed={isFailed}
        progress={isUploading ? uploadProgress : undefined}
        uploadId={uploadId}
        onCancel={onCancel}
      >
        <div
          style={{
            ...mediaCss,
            ...(!imgLoaded &&
              mediaOnlyMessage && {
                minHeight: "100%",
                minWidth: "100%",
              }),
          }}
          css={
            loadThumbnail
              ? {
                  backgroundImage: `url(${thumbnailUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  ":before": {
                    content: '""',
                    position: "absolute",
                    inset: "0",
                    opacity: "0",
                    background: "rgba(255,255,255,0.25)",
                    animation: `${keyframes({
                      "50%": {
                        opacity: "0.5",
                      },
                    })} 2s ease-in-out infinite`,
                  },
                  ":after": {
                    content: '""',
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backdropFilter: "blur(10px)",
                    animation: `${keyframes({
                      "100%": {
                        opacity: "0.5",
                      },
                    })} 6s ease-in-out forwards`,
                  },
                }
              : undefined
          }
        >
          <img
            onClick={(e) => {
              e.stopPropagation();
              if (isDragging) {
                return;
              }

              if (imgLoaded && !isFailed && uploadProgress !== -1) {
                setIsFullScreen(true);
              }
            }}
            src={mediaUrl}
            title={contentDescription}
            alt={
              imgLoadErr
                ? contentDescription ||
                  url.replace("https://", "").substring(0, 20) + "..."
                : undefined
            }
            style={{
              ...mediaCss,
              ...(!isFullScreen &&
                imgLoaded &&
                mediaOnlyMessage && {
                  cursor: "pointer",
                }),
              ...(!imgLoaded &&
                mediaOnlyMessage && {
                  minHeight: "100%",
                  minWidth: "100%",
                }),
              ...(thumbnailUrl && {
                opacity: imgLoaded ? "1" : "0",
              }),
              transition: "opacity 200ms ease-in-out",
            }}
            css={cssIfFailOrProgress}
            draggable={false}
            onLoad={() => setImgLoaded(true)}
            onError={() => setImgLoadErr(true)}
            loading="lazy"
          />
        </div>
      </Media>
    );

    return (
      <>
        <FullscreenViewer
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
        >
          {imageComponent}
        </FullscreenViewer>
        {imageComponent}
      </>
    );
  } else if (type.includes("video")) {
    const mediaRef = useRef<HTMLDivElement>(null!);
    const [loaded, setLoaded] = useState(false);

    // Some browsers will fail mkv and mov files unless we force them to mp4
    const contentType =
      type === "video/x-matroska" || type === "video/quicktime"
        ? "video/mp4"
        : type;

    const isUploadProgress =
      typeof uploadProgress == "number" && uploadProgress < 100;

    useEffect(() => {
      // Set loaded to true after a short delay to mimic the video loading
      if (!isUploadProgress) {
        const timer = setTimeout(() => setLoaded(true), 500);
        return () => clearTimeout(timer);
      }
    }, [isUploadProgress]);

    return (
      <Media
        ref={mergeRefs(ref, mediaRef)}
        isFailed={isFailed}
        progress={isUploadProgress ? uploadProgress : !loaded}
        style={mediaCss}
        uploadId={uploadId}
        onCancel={onCancel}
      >
        <VideoPlayer
          src={url}
          type={contentType}
          poster={thumbnailUrl}
          style={mediaCss}
        />
      </Media>
    );
  } else if (type.includes("audio")) {
    const [audioPlaying, setAudioPlaying] = useAtom(
      atoms.messaging.audioPlaying
    );

    const audioPlayerRef = useRef<AudioPlayerAPI>(null);

    const handleAudioPlayClick = () => {
      if (audioPlayerRef.current) {
        if (audioPlaying) {
          console.log("pause the current playing audio");
          audioPlaying.pause();
        }
        setAudioPlaying(audioPlayerRef.current);
      }
    };

    const handleAudioPauseClick = () => {
      if (audioPlayerRef.current === audioPlaying) {
        setAudioPlaying(undefined);
      }
    };

    return (
      <Media
        ref={ref}
        isFailed={isFailed}
        progress={uploadProgress}
        uploadId={uploadId}
        onCancel={onCancel}
      >
        <AudioPlayer
          ref={audioPlayerRef}
          src={url}
          type={type}
          onPlay={handleAudioPlayClick}
          onPause={handleAudioPauseClick}
        />
      </Media>
    );
  } else if (type.includes("rcspushlocation")) {
    const [imgLoaded, setImgLoaded] = useReducer(() => true, false);
    const [imgLoadErr, setImgLoadErr] = useReducer(() => true, false);

    const latLng = attachment.contentDescription
      ? (JSON.parse(attachment.contentDescription) as LatLng)
      : undefined;

    return (
      <>
        <Map
          defaultLocation={latLng}
          closeMap={() => setMapOpen(false)}
          open={mapOpen}
        />

        <Media
          ref={ref}
          style={mediaCss}
          isFailed={isFailed}
          progress={uploadProgress}
          uploadId={uploadId}
          onCancel={onCancel}
        >
          {isFailed && <ErrorOverlay />}
          <ProgressSpinner progress={uploadProgress} uploadId={uploadId} />
          <div
            style={{
              ...mediaCss,
              ...(!imgLoaded &&
                mediaOnlyMessage && {
                  minHeight: "100%",
                  minWidth: "100%",
                }),
              cursor: "pointer",
            }}
          >
            <img
              onClick={(e) => {
                e.stopPropagation();
                if (isDragging) {
                  return;
                }

                setMapOpen(true);
              }}
              src={url}
              title={contentDescription}
              alt={
                imgLoadErr
                  ? contentDescription ||
                    url.replace("https://", "").substring(0, 20) + "..."
                  : undefined
              }
              style={{
                ...mediaCss,
                ...(!imgLoaded &&
                  mediaOnlyMessage && {
                    minHeight: "100%",
                    minWidth: "100%",
                  }),
              }}
              css={cssIfFailOrProgress}
              draggable={false}
              onLoad={setImgLoaded}
              onError={setImgLoadErr}
              loading="lazy"
            />
          </div>
        </Media>
      </>
    );
    // Special case for text files
  } else if (type.includes("application") || type.includes("text")) {
    return (
      <Media
        ref={ref}
        style={otherCss}
        isFailed={isFailed}
        progress={uploadProgress}
        uploadId={uploadId}
        onCancel={onCancel}
      >
        <div
          css={[
            {
              display: "flex",
              flexDirection: "row",
              minHeight: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
              lineHeight: "1",
              whiteSpace: "nowrap",
            },
            cssIfFailOrProgress,
          ]}
          onClick={downloadFile}
        >
          <GenericFile
            css={{
              height: "1.5em !important",
              width: "1.5em !important",
              color: colors.primaryTextColor,
            }}
          />
          <div
            css={{
              padding: "0.4rem",
            }}
          >
            <div
              css={{
                padding: "0.2rem 0",
              }}
            >
              <div
                css={{
                  borderRadius: "0",
                  display: "-webkit-inline-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "2",
                  whiteSpace: "normal",
                  lineHeight: "1.1",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "left",
                  width: "auto",
                }}
              >
                {fileName}
              </div>
            </div>
            <div
              css={{
                color: colors.secondaryTextColor,
                fontSize: "0.8em",
                fontWeight: "normal",
                marginTop: "0.1rem",
                textAlign: "left",
                width: "auto",
              }}
            >
              {formatFileSizeToHumanReadable(mediaFileSize)}
            </div>
          </div>
        </div>
      </Media>
    );
  }
  return null;
};

export default memo(Attachment);
