import { IconButton } from "@/components/shared/Button";
import { colors } from "@/styles/global.styles";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { useChatbotInfo } from "@/utils/hooks/useChatbotInfo";
import { useSelectedConversation } from "@/utils/messaging/conversation/ConversationState";
import styled from "@emotion/styled";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { AnimatePresence, motion } from "motion/react";
import React, { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Suggestion, SuggestionResponse } from "../chat/typings";
import { FooterDropdown, FooterDropdownItem } from "./ChatFooterDropdown";

export type PersistentMenuProps = {
  contact: WebGwContact;
};

export default function PersistentMenu({ contact }: PersistentMenuProps) {
  const conversation = useSelectedConversation()!;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpened((prev) => !prev);
  };
  const closeDropdown = () => {
    setDropdownOpened(false);
  };

  useOnClickOutside(
    // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
    dropdownRef,
    (e) => {
      e.stopPropagation();
      if (buttonRef.current?.contains(e.target as Node)) {
        return;
      }
      closeDropdown();
    },
    "mouseup"
  );

  const isChatbot = !!contact?.isChatbot;
  const { data, isLoading } = useChatbotInfo(contact);

  if (!isChatbot) {
    return null;
  }

  const persistentMenu = data?.persistentMenu;

  const renderPersistentMenu =
    isChatbot &&
    !!persistentMenu &&
    persistentMenu.length > 0 &&
    // TODO - for now we only support reply but not action, remove this condition when action will be supported
    persistentMenu.find((p) => p.reply);

  const renderPersistentMenuButton = isLoading || renderPersistentMenu;

  const performAction = (reply: Suggestion) => {
    if (!reply.postback) return;

    const msg = JSON.stringify({ response: { reply } } as SuggestionResponse);

    conversation.sendTextMessage(msg, true);
  };

  return (
    <>
      <AnimatePresence initial={false}>
        {renderPersistentMenuButton && (
          <>
            <motion.div
              initial={{ maxWidth: 40 }}
              exit={{ maxWidth: 0 }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              css={{
                overflow: "hidden",
                display: "flex",
              }}
            >
              <IconButton
                ref={buttonRef}
                onClick={toggleDropdown}
                disabled={isLoading}
                css={{
                  color: isLoading
                    ? colors.tertiaryTextColor
                    : colors.secondaryTextColor,
                  "&:hover": { color: colors.primaryTextColor },
                }}
              >
                <MenuOutlinedIcon />
              </IconButton>
              <FooterSpacer />
            </motion.div>
          </>
        )}
      </AnimatePresence>
      {renderPersistentMenu && (
        <FooterDropdown
          ref={dropdownRef}
          open={dropdownOpened}
          onClick={closeDropdown}
        >
          {persistentMenu.map(
            ({ reply }, idx) =>
              reply &&
              reply.postback?.data && (
                <React.Fragment key={idx}>
                  <FooterDropdownItem
                    onClick={() => {
                      performAction(reply);
                    }}
                  >
                    {reply.displayText}
                  </FooterDropdownItem>
                </React.Fragment>
              )
          )}
        </FooterDropdown>
      )}
    </>
  );
}

const FooterSpacer = styled.div({
  height: "100%",
  width: "0.25em",
});
