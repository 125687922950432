import FloatingTooltip from "@/components/shared/FloatingTooltip";
import IFrameModal from "@/components/shared/IFrameModal";
import { colors } from "@/styles/global.styles";
import { ease } from "@/utils/ease";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { animate, motion, usePresence } from "motion/react";
import { nanoid } from "nanoid";
import React, {
  CSSProperties,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { writeMessageToSelectedConversation } from "../../../../utils/messaging/conversation/conversationUtils/";
import {
  Action,
  Postback,
  Reply,
  SuggestionResponse,
  type Suggestions,
} from "../typings";
import { getHeightWithMargins, SuggestionsApi } from "../util/cardUtils";
import {
  suggestionGap,
  suggestionHeight,
  suggestionsCss,
} from "./suggestions.style";

type SuggestionsProps = {
  suggestions: Suggestions | undefined;
  style?: CSSProperties;
  /** true when the suggested chip list exists. This applies to richcards and text messages */
  outsideOfCard?: boolean;
} & (
  | {
      canShrink?: never;
      isShrunk?: never;
    }
  | {
      canShrink: boolean;
      isShrunk: boolean;
    }
);

function performAction(buttonName: string, postback?: Postback) {
  // construct json response to chatbots when clicking a suggestion
  // ex: `{"response":{"reply":{"postback":{"data":"nextState=cart-getCart"},"displayText":"View Cart"}}}`

  const responseMessage = JSON.stringify({
    response: {
      reply: {
        postback,
        displayText: buttonName,
      },
    },
  } satisfies SuggestionResponse);

  const msgId = nanoid();

  writeMessageToSelectedConversation({
    msgId,
    originalMsgId: msgId,
    textMessage: responseMessage,
    direction: "Out",
    time: new Date().toISOString(),
  });
}

const inCardMaxSuggestions = 2;
export default function Suggestions({
  ref,
  suggestions,
  style,
  isShrunk: _isShrunk,
  canShrink,
  outsideOfCard = false,
}: SuggestionsProps & {
  ref?: React.Ref<SuggestionsApi>;
}) {
  const isShrunk = !!_isShrunk;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  const limitSuggestions =
    !outsideOfCard &&
    isShrunk &&
    suggestions &&
    suggestions.length > inCardMaxSuggestions;

  const getHeight = () => {
    const height = wrapperRef.current?.offsetHeight ?? 0;
    wrapperRef.current?.setAttribute("data-max-height", height.toString());
    return height;
  };

  useImperativeHandle(
    ref,
    () => ({
      elem: wrapperRef.current,
      getHeight,
      getTopPadding: () =>
        mainRef.current
          ? parseFloat(getComputedStyle(mainRef.current).paddingTop)
          : 0,
    }),
    []
  );

  const innerSuggestionsHeightRef = useRef("0");
  useLayoutEffect(() => {
    if (!mainRef.current || !suggestions || suggestions.length === 0) return;
    innerSuggestionsHeightRef.current =
      getHeightWithMargins(mainRef.current) + "px";
  }, []);

  useLayoutEffect(() => {
    // only enable suggestion height animation after first render
    // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
    setTimeout(() => {
      const elem = wrapperRef.current;
      if (!elem) return;
      elem.style.transition = "max-height var(--t)";
    });
  }, []);

  useLayoutEffect(() => {
    const elem = wrapperRef.current;
    if (!elem) return;
    if (!isPresent || !suggestions || suggestions.length === 0) return;

    elem.style.maxHeight = limitSuggestions
      ? // animating this calc didn't work using motion/react, so I'm using css transition instead
        `calc(${inCardMaxSuggestions} * (${suggestionHeight} + ${suggestionGap}) + ${/* bottom padding/gap */ "0.75rem"})`
      : innerSuggestionsHeightRef.current;
  }, [limitSuggestions]);

  const [isPresent, safeToRemove] = usePresence();

  useLayoutEffect(() => {
    if (isPresent) return;

    if (!outsideOfCard) {
      safeToRemove();
      return;
    }

    const elem = wrapperRef.current;
    if (!elem) return;

    elem.style.transition = "";
    elem.style.maxHeight = "";

    void animate(
      elem,
      { maxHeight: [innerSuggestionsHeightRef.current, 0] },
      { duration: 0.35, ease: ease }
    )
      // eslint-disable-next-line promise/prefer-await-to-then
      .then(safeToRemove);
  }, [isPresent]);

  return (
    <div
      ref={wrapperRef}
      css={[suggestionsCss.wrapper, !outsideOfCard && suggestionsCss.sticky]}
      style={outsideOfCard ? { overflow: "hidden" } : undefined}
    >
      {canShrink && <SuggestionsBackground show={isShrunk} />}
      <div
        ref={mainRef}
        css={suggestionsCss.main}
        style={{
          ...style,
          ...(suggestions
            ? suggestions.length === 1
              ? { flexWrap: "nowrap" }
              : {}
            : {
                padding: "0",
              }),
        }}
      >
        {suggestions ? (
          suggestions.map((suggestion, idx) => {
            if ("reply" in suggestion) {
              // eslint-disable-next-line @eslint-react/no-array-index-key
              return <ReplySuggestion key={idx} suggestion={suggestion} />;
            } else if ("action" in suggestion) {
              // eslint-disable-next-line @eslint-react/no-array-index-key
              return <ActionSuggestion key={idx} suggestion={suggestion} />;
            }
            return null;
          })
        ) : canShrink ? (
          // spacer for the expand button
          <div style={{ height: isShrunk ? "0" : "32px" }} />
        ) : null}
        {canShrink && (
          <div
            css={{
              position: "absolute",
              right: "-0.25em",
            }}
            style={suggestions ? { top: "0" } : { bottom: "0.5em" }}
          >
            <ShrinkExpandButton isShrunk={isShrunk} />
          </div>
        )}
      </div>
    </div>
  );
}

function ShrinkExpandButton({ isShrunk }: { isShrunk: boolean }) {
  return (
    <FloatingTooltip
      tooltipContent={isShrunk ? "Expand" : "Shrink"}
      placement="top"
      disableShift
      mountToBody
    >
      <motion.div
        initial={{ rotate: "0deg" }}
        animate={{ rotate: isShrunk ? "0deg" : "180deg" }}
        whileTap={{ scale: 0.95 }}
        css={{
          color: colors.primaryTextColor,
          lineHeight: "0",
          cursor: "pointer",
          borderRadius: "50%",
          background: colors.primaryAccentColor,
          pointerEvents: "auto",
          border: `2px solid ${colors.primaryTextColor}`,
        }}
      >
        <ExpandMoreRoundedIcon css={{ width: "28px", height: "28px" }} />
      </motion.div>
    </FloatingTooltip>
  );
}

function SuggestionsBackground({ show }: { show: boolean }) {
  return (
    <>
      <motion.div
        css={{
          pointerEvents: "none",
          zIndex: "-1",
          position: "absolute",
          bottom: "0",
          height: "115px",
          width: "200%",
          left: "50%",
          transform: "translateX(-50%)",
          background: "linear-gradient(transparent 0%, rgba(0,0,0,0.5) 100%)",
        }}
        initial={{ opacity: 1 }}
        animate={{ opacity: show ? 1 : 0 }}
        transition={{ duration: 0.25 }}
      />
    </>
  );
}

function ReplySuggestion({ suggestion }: { suggestion: Reply }) {
  return (
    <div
      css={suggestionsCss.button}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        performAction(suggestion.reply.displayText, suggestion.reply.postback);
      }}
    >
      {suggestion.reply.displayText}
    </div>
  );
}

function ActionSuggestion({ suggestion }: { suggestion: Action }) {
  const [openWebView, setOpenWebView] = useState(false);
  let subject: string | undefined;
  let url: string | undefined;
  const action = suggestion.action;
  if (action.urlAction) {
    url = action.urlAction.openUrl.url;
  } else if (action.dialerAction) {
    if (action.dialerAction.dialEnrichedCall) {
      url =
        action.dialerAction.dialEnrichedCall.phoneNumber ||
        action.dialerAction.dialEnrichedCall.fallbackUrl;
      subject = action.dialerAction.dialEnrichedCall.subject;
    } else if (action.dialerAction.dialPhoneNumber) {
      url =
        action.dialerAction.dialPhoneNumber.phoneNumber ||
        action.dialerAction.dialPhoneNumber.fallbackUrl;
    } else if (action.dialerAction.dialVideoCall) {
      url =
        action.dialerAction.dialVideoCall.phoneNumber ||
        action.dialerAction.dialVideoCall.fallbackUrl;
    }
  }
  if (!url) return null;

  if (url.startsWith("+")) {
    url = `tel:${url}`;
  } else {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // check if url is an email
    if (emailRegex.test(url)) {
      url = `mailto:${url}`;
    }
  }

  return (
    <>
      <a
        css={suggestionsCss.button}
        href={url}
        title={subject}
        target="_blank"
        onClick={(e) => {
          e.stopPropagation();

          if (suggestion.action.urlAction?.openUrl.application === "webview") {
            e.preventDefault();
            setOpenWebView(true);
          }

          if (!suggestion.action.postback) {
            return;
          }

          performAction(
            suggestion.action.displayText,
            suggestion.action.postback
          );
        }}
        rel="noreferrer"
      >
        {suggestion.action.displayText}
      </a>
      {openWebView && (
        <IFrameModal url={url} onClose={() => setOpenWebView(false)} />
      )}
    </>
  );
}
