import {
  MediaPlayer,
  MediaPlayerInstance,
  MediaProvider,
  MediaRemoteControl,
  useMediaRemote,
  VideoMimeType,
} from "@vidstack/react";
// Base styles for media player and provider (~400B).
import "@vidstack/react/player/styles/base.css";
import React, { useEffect, useRef, useState } from "react";
import { useContainerBreakpoints } from "../../../utils/hooks/useContainerBreakpoints";
import { VideoLayout } from "./VideoLayout";

const fontSizeBreakpoints = {
  // < 300 is 0.5rem
  xs: 300,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1600,
};

function getFontSizeByBreakpoint(
  breakpoint: keyof typeof fontSizeBreakpoints | undefined
) {
  switch (breakpoint) {
    case "xs":
      return "0.75rem";
    case "sm":
      return "1rem";
    case "md":
      return "1.125rem";
    case "lg":
      return "1.25rem";
    case "xl":
      return "1.5rem";
    default:
      return "0.5rem";
  }
}

type VideoPlayerProps = {
  src: string;
  type?: string;
  poster?: string;
  onPlay?: () => void;
  onPause?: () => void;
  style?: React.CSSProperties;
};

let playingVideoRemote: MediaRemoteControl | null = null;
const remotes = new Set<MediaRemoteControl>();

export default function VideoPlayer({
  src,
  type,
  poster,
  onPlay,
  onPause,
  style,
}: VideoPlayerProps) {
  const mediaPlayerRef = useRef<MediaPlayerInstance>(null!);

  const _breakpoint = useContainerBreakpoints({
    breakpoints: fontSizeBreakpoints,
    getContainer: () => mediaPlayerRef.current.el,
    onBreakpointChange: (breakpoint) => {
      if (!mediaPlayerRef.current.el) {
        console.error("Media player element not found");
        return;
      }
      console.log("breakpoint found", breakpoint);
      mediaPlayerRef.current.el.style.fontSize = getFontSizeByBreakpoint(
        breakpoint?.key
      );
    },
  });

  const remote = useMediaRemote(mediaPlayerRef);
  useEffect(() => {
    remotes.add(remote);
    return () => {
      remotes.delete(remote);
    };
  }, [remote]);

  const removePlayingRemote = () => {
    if (playingVideoRemote === remote) {
      playingVideoRemote = null;
    }
  };

  useEffect(() => {
    return removePlayingRemote;
  }, []);

  // without this, the video would be loaded multiple times and once for every time navigation
  // TODO: remove once nms sends the correct video payload
  const videoUrl = useNmsVideo(src);

  return (
    <MediaPlayer
      ref={mediaPlayerRef}
      className={`media-player group aspect-video w-full overflow-hidden rounded-md font-sans text-sm text-white [container-name:media-player] data-[focus]:ring-4`}
      title="Sprite Fight"
      src={
        videoUrl ? { src: videoUrl, type: type as VideoMimeType } : undefined
      }
      poster={poster}
      style={style}
      onDurationChange={(duration) => remote.changeDuration(duration)}
      onPlay={() => {
        if (playingVideoRemote && playingVideoRemote !== remote) {
          playingVideoRemote.pause();
        }
        playingVideoRemote = remote;
        onPlay?.();
      }}
      onEnd={removePlayingRemote}
      onPause={onPause}
      storage={"video-player-storage"}
      onVolumeChange={({ muted, volume }) => {
        for (const otherRemote of remotes) {
          if (otherRemote === remote) continue;

          otherRemote.changeVolume(volume);
          if (muted) {
            otherRemote.mute();
          } else {
            otherRemote.unmute();
          }
        }
      }}
    >
      <MediaProvider className="h-full w-full [&>video]:h-full [&>video]:w-full [&>video]:[object-fit:cover] group-data-[fullscreen]:[&>video]:[object-fit:contain]" />
      <VideoLayout />
    </MediaPlayer>
  );
}

function useNmsVideo(url: string) {
  const [videoUrl, setVideoUrl] = useState<string | null>(
    url.includes("nms") ? null : url
  );

  useEffect(() => {
    if (videoUrl) {
      return () => {
        if (videoUrl !== url) {
          URL.revokeObjectURL(videoUrl);
        }
      };
    }

    const abortController = new AbortController();

    const fetchVideo = async () => {
      try {
        const response = await fetch(url, {
          credentials: "include",
          signal: abortController.signal,
        });

        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setVideoUrl(objectUrl);
      } catch (error) {
        console.error("Error fetching video from NMS:", error);
      }
    };

    void fetchVideo();

    return () => {
      abortController.abort();
    };
  }, [videoUrl, url]);

  return videoUrl;
}
