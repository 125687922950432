import { FilePreviewRes, FileWithProgress } from "@/utils/hooks/useFilePreview";
import TrashIcon from "@heroicons/react/16/solid/TrashIcon";
import DocumentIcon from "@heroicons/react/24/solid/DocumentIcon";
import Color from "color";
import { AnimatePresence, Reorder, useDragControls } from "motion/react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { colors } from "../../styles/global.styles";
import { AnimateChangeInHeight } from "../shared/AnimateChangeInHeight";
import { IconButton } from "../shared/Button";
import Attachment from "./chat/components/Attachment";
import { ChatFooterRef } from "./ChatFooter";

type FileTransferPreviewProps = {
  filePreviewRes: FilePreviewRes;
  isOnOverlay?: boolean;
  chatFooterRef: React.RefObject<ChatFooterRef | null>;
};

export default function FileTransferPreview({
  filePreviewRes,
  isOnOverlay,
  chatFooterRef,
}: FileTransferPreviewProps) {
  const filesWithProgress = useSnapshot(filePreviewRes.pendingFilesProxy);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (filePreviewRes.pendingFilesProxy.length > 0) {
      chatFooterRef.current?.focus();
    }
  }, [filesWithProgress]);

  const bgColor = new Color(colors.secondaryBackground).darken(0.15).toString();

  return (
    <AnimateChangeInHeight
      ref={containerRef}
      css={{
        display: "flex",
        alignItems: "center",
        width: isOnOverlay ? "100%" : "calc(100% - 1em * 2)",
        borderRadius: "10px 10px 0px 0px",
        // 10px to cover the border radius gap in between this and the message box
        boxShadow: `0 10px 0 0 ${bgColor}`,
        transform: "translateY(1px)",
        backgroundColor: bgColor,
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          height: "6px",
        },
        "::-webkit-scrollbar-track": {
          margin: `12px 0`,
          background: "transparent",
        },
      }}
    >
      <AnimatePresence mode="popLayout">
        <Reorder.Group
          as="ol"
          axis="x"
          values={filesWithProgress}
          onReorder={(newFilesOrder) => {
            // Clear the array and push the new order, similar to setting the array
            filePreviewRes.pendingFilesProxy.splice(
              0,
              filePreviewRes.pendingFilesProxy.length
            );
            filePreviewRes.pendingFilesProxy.push(...newFilesOrder);
          }}
          layoutScroll
          css={{
            display: "flex",
          }}
        >
          {filesWithProgress.map((fileWithProgress) => (
            <FilePreview
              key={fileWithProgress.file.name}
              fileWithProgress={fileWithProgress}
              onRemoveFile={filePreviewRes.handleRemoveFile}
              containerRef={containerRef}
            />
          ))}
        </Reorder.Group>
      </AnimatePresence>
    </AnimateChangeInHeight>
  );
}

function FilePreview({
  fileWithProgress,
  onRemoveFile,
  containerRef,
}: {
  fileWithProgress: FileWithProgress;
  onRemoveFile: (file: File) => void;
  containerRef: React.RefObject<HTMLDivElement | null>;
}) {
  const { file, progress, uploadId } = useSnapshot(fileWithProgress);

  const isImage = file.type.startsWith("image/");
  const isVideo = file.type.startsWith("video/");
  const isAudio = file.type.startsWith("audio/");
  const needsPreview = isImage || isVideo || isAudio;
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useLayoutEffect(() => {
    if (needsPreview) {
      const url = URL.createObjectURL(fileWithProgress.file);
      setPreviewUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [needsPreview]);

  const attachmentRef = useRef<HTMLDivElement>(null);

  const dragControls = useDragControls();

  return (
    <Reorder.Item
      ref={(elem: HTMLLIElement | null) => {
        if (!elem) return;
        elem.scrollIntoView({
          behavior: "smooth",
          inline: "end",
          block: "end",
        });
        elem.addEventListener("pointerdown", (e) => {
          // Check if the target is or contains a video control element
          if ((e.target as HTMLElement).closest(".video-controls")) {
            return;
          }
          dragControls.start(e);
        });
      }}
      dragListener={false}
      dragControls={dragControls}
      dragElastic={0.15}
      dragConstraints={containerRef}
      // disable image zooming when dragging
      onDragStart={(e) => {
        const elem = attachmentRef.current;
        if (!elem) {
          console.error("No media element found for drag start", e.target);
          return;
        }
        elem.style.pointerEvents = "none";
      }}
      onDragEnd={(e) => {
        const elem = attachmentRef.current;
        if (!elem) {
          console.error("No media element found for drag end", e.target);
          return;
        }
        elem.style.pointerEvents = "";
      }}
      value={fileWithProgress}
      css={{
        backgroundColor: colors.secondaryBackgroundLighter,
        borderRadius: "8px",
        padding: "12px",
        margin: "15px 8px 12px 0",
        "&:first-of-type": {
          marginLeft: "12px",
        },
        "&:last-of-type": {
          marginRight: "12px",
        },
        cursor: "grab",
        "&:active": {
          cursor: "grabbing",
        },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        opacity: {
          duration: 0.5,
        },
      }}
    >
      <div
        css={{
          position: "relative",
          width: "120px",
          height: "100px",
          marginBottom: "8px",
          borderRadius: "6px",
          overflow: "hidden",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        {previewUrl ? (
          <Attachment
            ref={attachmentRef}
            attachment={{
              mediaContentType: file.type,
              mediaUrl: previewUrl,
              height: "SHORT_HEIGHT",
              mediaFileSize: file.size,
            }}
            uploadProgress={progress}
            uploadId={uploadId}
            onCancel={() => onRemoveFile(file)}
          />
        ) : (
          <div
            ref={attachmentRef}
            css={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DocumentIcon width={48} height={48} />
          </div>
        )}
        <IconButton
          onClick={() => onRemoveFile(file)}
          css={{
            position: "absolute",
            right: "4px",
            top: "4px",
            padding: "4px",
            color: colors.primaryTextColor,
            backgroundColor: new Color(colors.fourthBackground)
              .alpha(0.5)
              .toString(),
            "&:hover": {
              backgroundColor: colors.fourthBackground,
            },
          }}
        >
          <TrashIcon />
        </IconButton>
      </div>
      <div
        css={{
          fontSize: "0.8rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "120px",
        }}
        title={file.name}
      >
        {file.name}
      </div>
    </Reorder.Item>
  );
}
